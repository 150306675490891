import axios from 'axios';

import config from '@/utility/config';

const staticServerProtectedAxiosRequest = axios.create();

const { apiStaticDataAuthToken } = config;
const staticServerProtectedReqConfigMiddleware = (config) => {
  const argHeaders = config?.headers || {};
  config.headers = {
    'auth-token': apiStaticDataAuthToken,
    ...argHeaders
  };
  return config;
};

const staticServerProtectedReqErrorMiddleware = (error) => {
  return Promise.reject(error);
};

const staticServerProtectedResMiddleware = (response) => response;

const staticServerProtectedResErrorMiddleware = async (resError) =>
  Promise.reject(resError);

staticServerProtectedAxiosRequest.interceptors.request.use(
  staticServerProtectedReqConfigMiddleware,
  staticServerProtectedReqErrorMiddleware
);

staticServerProtectedAxiosRequest.interceptors.response.use(
  staticServerProtectedResMiddleware,
  staticServerProtectedResErrorMiddleware
);

export default staticServerProtectedAxiosRequest;
