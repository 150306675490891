import { showStartOnboardingModalInDemo } from '@/utility/productDemoUtils';

export const CHAT_CTA_LOCATION = 'CHAT_CTA_LOCATION'; // done
export const LANDING_PAGE_CTA_LOCATION = 'LANDING_PAGE_CTA_LOCATION';
export const INVITE_MEMBERS_SECTION_LOCATION =
  'INVITE_MEMBERS_SECTION_LOCATION'; // done
export const EVENT_LANDING_PAGE_CTA = 'EVENT_LANDING_PAGE_CTA'; // done
export const DOWNLOAD_RSVP_CTA = 'DOWNLOAD_RSVP_CTA'; // done
export const RESOURCE_PAGE_CTA = 'RESOURCE_PAGE_CTA';
export const CHANGE_PRICE_CTA = 'CHANGE_PRICE_CTA'; // done
export const APPLICATION_FORM_ON_CMP_CTA = 'APPLICATION_FORM_ON_CMP_CTA'; // done
export const PROMO_CODE_CTA = 'PROMO_CODE_CTA'; // done
export const MAGIC_REACH_SEND_CTA = 'MAGIC_REACH_SEND_CTA'; // done
export const MAGIC_REACH_SEND_TEST_CTA = 'MAGIC_REACH_SEND_TEST_CTA'; // done
export const EDIT_WELCOME_MESSAGE_SEND_TEST_CTA =
  'EDIT_WELCOME_MESSAGE_SEND_TEST_CTA'; // done
export const EVENT_SCHEDULED_MESSAGES_SEND_TEST_CTA =
  'EVENT_SCHEDULED_MESSAGES_SEND_TEST_CTA'; // done
export const DISCONNECT_CHAT_CTA = 'DISCONNECT_CHAT_CTA'; // done
export const DEMO_CMP_SETTINGS_OPEN_CHAT_CTA =
  'DEMO_CMP_SETTINGS_OPEN_CHAT_CTA'; // done
export const UNLOCK_WHATSAPP_CTA = 'UNLOCK_WHATSAPP_CTA'; // done
export const EMAIL_FROM_PRODUCT_DEMO = 'EMAIL_FROM_PRODUCT_DEMO';

export function handleDemoStateForCta(locationOfCta) {
  switch (locationOfCta) {
    case LANDING_PAGE_CTA_LOCATION:
      return {}; // already working
    case CHAT_CTA_LOCATION:
    case DEMO_CMP_SETTINGS_OPEN_CHAT_CTA:
    case INVITE_MEMBERS_SECTION_LOCATION:
    case EVENT_LANDING_PAGE_CTA:
    case DOWNLOAD_RSVP_CTA:
    case RESOURCE_PAGE_CTA:
    case CHANGE_PRICE_CTA:
    case APPLICATION_FORM_ON_CMP_CTA:
    case PROMO_CODE_CTA:
    case MAGIC_REACH_SEND_CTA:
    case MAGIC_REACH_SEND_TEST_CTA:
    case DISCONNECT_CHAT_CTA:
    case UNLOCK_WHATSAPP_CTA:
    case EDIT_WELCOME_MESSAGE_SEND_TEST_CTA:
    case EVENT_SCHEDULED_MESSAGES_SEND_TEST_CTA:
      return showStartOnboardingModalInDemo(locationOfCta);
    default:
  }
}
