import { convertQueryStringToObject } from '@/services/helpers/queryString';

export const getQuerySearchParam = (key) => {
  const queryParamsObj = {};
  if (typeof window === 'undefined') {
    return null;
  }
  window.location.search
    .substring(1)
    .split('&')
    .forEach((item) => {
      const [key, value] = item.split('=');
      queryParamsObj[key] = value;
    });

  return queryParamsObj[key];
};

export const getQueryParams = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  const queryStr = window.location.search || '';

  const queryParamsJson = convertQueryStringToObject(queryStr);
  return queryParamsJson;
};
export const resetQueryParams = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  window.history.pushState(
    {
      path: window.location.origin + window.location.pathname
    },
    '',
    window.location.origin + window.location.pathname
  );
};
export const addPrefixToUrlIfRequired = (url) => {
  try {
    if (url.startsWith('https') || url.startsWith('http')) return url;

    return `https://${url}`;
  } catch (e) {
    console.error(e);
    return url;
  }
};

export const buildUrlWithQueryParams = (url, params) => {
  const entries = Object.entries(params);
  if (!params || !entries.length) {
    return url;
  }

  // eslint-disable-next-line no-unused-vars
  const filteredEntries = entries.filter(([_, value]) => value);

  if (!filteredEntries.length) {
    return url;
  }

  const filteredParams = Object.fromEntries(filteredEntries);

  return `${url}?${new URLSearchParams(filteredParams).toString()}`;
};

export const getSearchStringFromPath = (path) => {
  if (!path) {
    return '';
  }

  const pathParts = path.split('?');

  return pathParts.length > 1 ? '?' + pathParts[1] : '';
};

export const getQueryStrFromObj = (obj) => {
  const params = new URLSearchParams();

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      params.append(key, obj[key]);
    }
  }
  return params.toString();
};

export function getDomain(url) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}
