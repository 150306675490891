import React, { useEffect } from 'react';

import config from '@/utility/config';
import { resolveBaseUrl } from '@/pages/portal/utils/events';
import { useCommunityPortalContext } from '@/contexts/CommunityPortalContext';
import { usePathname } from 'next/navigation';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import { useAuthContext } from '../AuthContext';
import {
  IntercomProvider,
  useIntercom
} from '@/contexts/IntercomContext/CommonIntercomContext';

export const IntercomContext = React.createContext();

export const InnerIntercomContextProvider = ({ children }) => {
  const pathname = usePathname();
  const { shutdown, boot } = useIntercom();
  const { activeCommunity } = useCommunityPortalContext();
  const isDemoCommunity = activeCommunity?.isDemo;
  const { user } = useAuthContext();

  const { isGtEqMd } = useWindowWidthContext();

  useEffect(() => {
    if (!pathname.startsWith('/portal') || isDemoCommunity) {
      shutdown();
      return;
    }

    if (user && activeCommunity) {
      let communityAccessType = 'Free';
      if (activeCommunity?.isPaidCommunity) {
        communityAccessType = 'Paid';
      } else if (activeCommunity?.isFreeCommunity) {
        communityAccessType = 'Free';
      } else if (activeCommunity?.isTokenGated) {
        communityAccessType = 'Token Gated';
      }

      const avatarUrl =
        user?.learner?.profileImage ??
        activeCommunity?.thumbnailImgData?.mobileImgData?.src;

      const displayName =
        user?.learner?.firstName || user?.learner?.lastName
          ? `${user?.learner?.firstName} ${user?.learner?.lastName}`
          : user?.fullName;

      boot({
        name: displayName,
        email: user.email,
        user_id: user.user_id,
        created_at: user?.createdAt
          ? new Date(user.createdAt).toISOString()
          : null,
        url: pathname,
        community_id: activeCommunity?._id,
        community_name: activeCommunity?.title,
        community_code: activeCommunity?.code,
        community_page_url: resolveBaseUrl(
          activeCommunity?.communityShortCode
        ),
        community_create_date: new Date(
          activeCommunity?.createdAt ?? null
        )?.toISOString(),
        community_members: activeCommunity?.members,
        community_access: communityAccessType,
        avatar: {
          type: 'avatar',
          image_url: avatarUrl
        }
      });
    }
  }, [activeCommunity, isGtEqMd, pathname]);

  // shutdown intercom if component unmounts
  useEffect(() => {
    return () => {
      shutdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntercomContext.Provider value={{}}>
      {children}
    </IntercomContext.Provider>
  );
};

export const IntercomContextProvider = ({ children }) => {
  const apiBase = config.intercomApiBase;
  const appId = config.intercomAppId;
  return (
    <IntercomProvider appId={appId} apiBase={apiBase}>
      <InnerIntercomContextProvider>
        {children}
      </InnerIntercomContextProvider>
    </IntercomProvider>
  );
};
