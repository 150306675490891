import config from '@/utility/config';

import { getAxiosError } from './helpers';
import adminProtectedAxiosRequest from './helpers/adminProtectedAxiosRequest';

const { communityApiBasePath } = config;

export const getTaskProgressService = async (communityId) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/task-progress`;
    const res = await adminProtectedAxiosRequest.get(path);
    return { data: res.data.data, error: null };
  } catch (error) {
    return getAxiosError(error);
  }
};

export const updateTaskProgress = async (communityId, payload) => {
  try {
    const path = `${communityApiBasePath}/api/v1/communities/${communityId}/task-progress`;
    const res = await adminProtectedAxiosRequest.put(path, payload);

    return { data: res.data.data, error: null };
  } catch (error) {
    return getAxiosError(error);
  }
};
