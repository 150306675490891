import { useEffect, useState } from 'react';

import {
  checkIfProcessingFreePreferenceSet,
  updateNasioProcessingFeePreference
} from '@/services/communitiesService';

// import { PROCESSING_FEE_PERC } from '../constants';

const useNasioFeePreference = ({
  activeCommunity,
  setActiveCommunity = null
}) => {
  const activeCommunityId = activeCommunity?._id;
  const [nasioFeePreferenceIsSet, setNasioFeePreferenceIsSet] =
    useState(false);
  const [isSettingFeePreference, setIsSettingFeePreference] =
    useState(false);

  const [showFeePreferencePopup, setShowFeePreferencePopup] =
    useState(false);

  const takeRateFeePercentage = activeCommunity?.paymentDetails;

  const verifyNasioFeePreferenceIsSet = () => {
    if (!nasioFeePreferenceIsSet) {
      setShowFeePreferencePopup(true);
    }
  };

  const closeFeePreferencePopup = () => {
    setShowFeePreferencePopup(false);
  };

  const updateNasioFeePreference = async ({ passOnTakeRate }) => {
    setIsSettingFeePreference(true);
    const res = await updateNasioProcessingFeePreference({
      communityId: activeCommunityId,
      passOnTakeRate
    });
    const { data, error } = res;

    setIsSettingFeePreference(false);

    if (error) {
      console.warn('Error in updateNasioFeePreference', error);
      return res;
    }

    if (data) {
      setNasioFeePreferenceIsSet(true);
    }

    setActiveCommunity?.((activeCommunity) => {
      return {
        ...activeCommunity,
        passOnTakeRate
      };
    });

    return res;
  };

  useEffect(() => {
    if (activeCommunityId) {
      const fetchNasioFeePreference = async () => {
        const { data, error } = await checkIfProcessingFreePreferenceSet({
          communityId: activeCommunityId
        });

        if (error) {
          console.warn(error);
          return;
        }

        if (data) {
          setNasioFeePreferenceIsSet(true);
        }
      };
      fetchNasioFeePreference();
    }
  }, [activeCommunityId]);

  return {
    nasioFeePreferenceIsSet,
    verifyNasioFeePreferenceIsSet,
    updateNasioFeePreference,
    isSettingFeePreference,
    showFeePreferencePopup,
    closeFeePreferencePopup,
    takeRateFeePercentage
  };
};

export default useNasioFeePreference;
