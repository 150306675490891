// const { whatsappGodModeBePath } = config;
import axios from 'axios';

import config from '@/utility/config';

// import config from '@/utility/config';
import {
  getAxiosError,
  getAxiosErrorMsg,
  getDataFromAxiosRes
} from './helpers';
// import { getAxiosError } from './helpers';
import adminProtectedAxiosRequest from './helpers/adminProtectedAxiosRequest';
// import staticServerProtectedAxiosRequest from './helpers/staticServerProtectedAxiosRequest';
import authorizedRequest from './helpers/authorizedRequest';
import staticServerProtectedAxiosRequest from './helpers/staticServerProtectedAxiosRequest';

const { apiStaticDataPath, communityApiBasePath, whatsappBotBePath } =
  config;

/**
 * Creates a whatsapp group when you supply the phoneId, phoneNumber, name, image
 * @param payload - This is the data that you want to send to the backend.
 * @returns The data is being returned.
 */
export const createAGroup = async (payload) => {
  // Convert json payload to formdata
  try {
    const res = await staticServerProtectedAxiosRequest.post(
      `${apiStaticDataPath}/api/v1/whatsapp-group`,
      payload
    );
    return res.data.data;
  } catch (error) {
    return getAxiosError(error);
  }
};

/*
 * @param {*} communityCode
 */
export const getWhatsappCommunityStatus = async (communityId) => {
  try {
    const path = `${communityApiBasePath}/api/v1/whatsapp/${communityId}/status`;

    const options = {
      method: 'GET'
    };

    const res = await axios.get(path, options);

    const data = await getDataFromAxiosRes(res);

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return { error: false, ...data };
  } catch (err) {
    return { error: true, data: getAxiosError(err) };
  }
};

/**
 * @param {*} params.phoneNumber
 * @param {*} params.communityId
 */
export const checkWaPhoneNumber = async (params) => {
  try {
    const path =
      communityApiBasePath +
      `/api/v1/check-phone-number?${new URLSearchParams(params)}`;

    const options = {
      method: 'GET'
    };

    const res = await axios.get(path, options);

    const data = await getDataFromAxiosRes(res);

    if (data.error) {
      throw new Error(data.info || data.error);
    }

    return { error: false, data };
  } catch (err) {
    return { error: true, data: getAxiosError(err) };
  }
};

export const updateLearner = async (
  updatedLearnerData,
  customAuthToken
) => {
  try {
    const apiPath = `${communityApiBasePath}/api/v1/learner`;

    const headers = {
      'Content-Type': 'application/json'
    };

    if (customAuthToken) {
      headers['Authorization'] = 'Bearer ' + customAuthToken;
    }

    const res = await authorizedRequest(apiPath, {
      method: 'PUT',
      body: JSON.stringify(updatedLearnerData),
      headers: headers
    });
    const data = await res.data;
    if (data.error || data.status === 'error') {
      const errMsg =
        data?.info ||
        data?.message ||
        data?.error ||
        'Something went wrong';
      throw new Error(errMsg);
    }
    return { error: false, data };
  } catch (err) {
    return { error: true, data: err.message };
  }
};

/**
 * @param {*} params.communityId
 * @Response data { groupName, membersCount }
 */
export async function getWaGroupStatus(params) {
  try {
    const { communityId } = params;
    const path = `${communityApiBasePath}/api/v1/whatsapp/${communityId}/status`;
    return await adminProtectedAxiosRequest.get(path);
  } catch (err) {
    return { error: true, data: getAxiosError(err) };
  }
}

/**
 * @param {*} params.communityId
 */
export async function resendEmailForMobileWaOnboarding(params) {
  try {
    const { communityId } = params;
    const payload = { communityId };

    const apiPath = `${communityApiBasePath}/api/v1/communities/community-manager/resend-email`;
    const { data, error } = await adminProtectedAxiosRequest.post(
      apiPath,
      payload
    );

    return { data, error };
  } catch (err) {
    return { error: getAxiosError(err) };
  }
}

export const setWaBotNumberForCommunity = async (communityId) => {
  try {
    const apiPath = `${whatsappBotBePath}/api/v1/assignBotToCommunity/${communityId}`;
    const res = await adminProtectedAxiosRequest(apiPath, {
      method: 'POST'
    });

    return res;
  } catch (err) {
    return { error: getAxiosErrorMsg(err) };
  }
};
