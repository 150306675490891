import { usePathname, useRouter } from 'next/navigation';
import { createContext, useContext, useEffect, useState } from 'react';

import { getPhoneNumberFromWaPhoneId } from '@/utility/communityHelpers';
import { COMMUNITY_ID_KEY } from '@/utility/constants';
import CookieService from '@/utility/cookieService';
import localStorageService from '@/utility/localStorageService';
import {
  CM_PORTAL_HOMEPAGE_ROUTE,
  MEMBER_PORTAL_BASED_PATH,
  PAYMENT_METHOD,
  USER_BASED_PATH,
  goToCmpHomeWithNewActiveCommunityId
} from '@/utility/routesHelper';
import sessionStorageService from '@/utility/sessionStorageService';
import { getQueryParams } from '@/utility/urlHelpers';

import useNasioFeePreference from '@/features/NasioProcessingFee/hooks/useNasioFeePreference';

import {
  getCommunitiesList,
  getCommunityAdmins,
  updateCommunity
} from '@/services/communitiesService';
import { getTaskProgressService } from '@/services/taskProgressService';
import { setWaBotNumberForCommunity } from '@/services/whatsappService';

import { IntercomContextProvider } from '@/contexts/IntercomContext/IntercomContext';

import { showErrorToast } from '@/components/common/ToastContainer';

import useLocale from '@/hooks/useLocale';
import useQueryParams from '@/hooks/useQueryParams';

import {
  UNLOCK_WHATSAPP_CTA,
  handleDemoStateForCta
} from '@/pages/product-demo/utilsAndContants';

import { useAuthContext } from '../AuthContext';

export const CommunityPortalContext = createContext();
export const DEFAULT_COMMUNITY_ADMIN_MAX_LIMIT = 10;

export const CommunityPortalContextProvider = ({ children }) => {
  const { user } = useAuthContext();
  const router = useRouter();
  const pathname = usePathname();

  const { currentRouterQuery } = useQueryParams();
  const { currentLocale } = useLocale();

  const [allCommunities, setAllCommunities] = useState([]);
  const [activeCommunity, setActiveCommunity] = useState();
  const [loadingCommunities, setLoadingCommunities] = useState(true);
  const [loadingCommunitiesAsync, setLoadingCommunitiesAsync] =
    useState(false);
  const [communitiesErr, setCommunitiesErr] = useState(null);
  const [communityAdminCount, setCommunityAdminCount] = useState(0);
  const [communityAdminMaxLimit, setCommunityAdminMaxLimit] = useState(
    DEFAULT_COMMUNITY_ADMIN_MAX_LIMIT
  );

  const [openMakeMoneyModal, setOpenMakeMoneyModal] = useState(false);
  const [taskProgressPerc, setTaskProgressPerc] = useState(0);

  //Global Welcome Message Editor
  const [isWelcomeMessageEditorOpen, setIsWelcomeMessageEditorOpen] =
    useState(false);

  const [
    membershipPendingApplicationCount,
    setMembershipPendingApplicationCount
  ] = useState(0);

  const activeCommunityId = activeCommunity?._id;
  const isDemoCommunity = activeCommunity?.isDemo;

  const {
    nasioFeePreferenceIsSet,
    verifyNasioFeePreferenceIsSet,
    showFeePreferencePopup,
    closeFeePreferencePopup,
    updateNasioFeePreference,
    isSettingFeePreference,
    takeRateFeePercentage
  } = useNasioFeePreference({
    activeCommunity,
    setActiveCommunity
  });

  const [showConnectWhatsAppModals, setShowConnectWhatsAppModals] =
    useState(false);
  const [showWaUnlockedBenefitsCard, setShowWaUnlockedBenefitsCard] =
    useState(false);

  const showWaBotConnectionBenefits =
    showWaUnlockedBenefitsCard && activeCommunity?.whatsappGroupName;

  // Is community a whatsapp experience community
  const isWhatsappExperienceCommunity =
    activeCommunity?.isWhatsappExperienceCommunity;

  const getAllCommunitiesAsync = async (activeCommunityId) => {
    //if user is not authenticated, we don't need to call /communities API
    if (!user) {
      return;
    }

    const storedCommunityId = CookieService.get(COMMUNITY_ID_KEY);

    setLoadingCommunitiesAsync(true);
    const { data: memberCommunitiesData, error: memberCommunitiesError } =
      await getCommunitiesList({
        //if we don't have any default data for community, we just use the current active community data
        activeCommunityId: activeCommunityId ?? storedCommunityId
      });

    if (memberCommunitiesError) {
      console.warn(
        'Error fetching all communities ',
        memberCommunitiesError
      );
      setCommunitiesErr(memberCommunitiesError.error);
      setLoadingCommunitiesAsync(false);
      return;
    }

    if (!memberCommunitiesData) {
      console.warn('Error fetching all communities data ', {
        memberCommunitiesData,
        memberCommunitiesError
      });
    }
    const onlySubscriberCommunities =
      memberCommunitiesData?.communities?.filter(
        (community) =>
          !community.subscriptions ||
          !community.subscriptions.length ||
          community.subscriptions?.[0]?.status?.toLowerCase() !== 'pending'
      );
    const managerCommunities =
      onlySubscriberCommunities
        ?.map((c) => {
          const { membersWithRoles } = c;
          if (membersWithRoles && membersWithRoles.length > 0) {
            const isManager = membersWithRoles[0].role.some(
              (role) => role === 'manager'
            );
            return {
              ...c,
              isManager,
              isMember: true
            };
          }
          return {
            ...c,
            isMember: true
          };
        })
        .sort((a, b) => {
          if (a.isManager && !b.isManager) return -1;
          if (!a.isManager && b.isManager) return 1;
          return a.title.localeCompare(b.title);
        }) || [];

    setAllCommunities(managerCommunities);
    const currentActiveCommunity =
      memberCommunitiesData?.activeCommunity?.[0];

    //if can not find any active community with the current id, we just need to pick the first one from the community list
    if (!currentActiveCommunity && managerCommunities?.length > 0) {
      await getAllCommunitiesAsync(managerCommunities[0]._id);
      return;
    }

    //if community is not manager, and is on /portal then redirect them to community link
    if (
      currentActiveCommunity &&
      !currentActiveCommunity.isManager &&
      pathname.includes(CM_PORTAL_HOMEPAGE_ROUTE)
    ) {
      router.push(currentActiveCommunity?.link);
      return;
    }

    setActiveCommunity(currentActiveCommunity);
    setLoadingCommunitiesAsync(false);
  };

  const getAllCommunities = async () => {
    try {
      const { activeCommunityId } = getQueryParams();
      await getAllCommunitiesAsync(activeCommunityId);
      setLoadingCommunities(false);
    } catch (e) {
      console.warn('Error in getAllCommunities ', e);
      setLoadingCommunities(false);
    }
  };

  const updateActiveCommunity = async (payload) => {
    const result = await updateCommunity(activeCommunity?._id, {
      ...payload,
      languagePreference: currentLocale
    });
    if (result.error) {
      showErrorToast(result.error);
      return { error: result.error };
    }
    setActiveCommunity({
      ...result.data,
      pendingApplications: activeCommunity?.pendingApplications //retain the number of pending applications
    });
    return { error: false };
  };

  const getCommunityAdminAsync = async (id) => {
    if (activeCommunity && !activeCommunity.isManager) {
      return;
    }

    const { data, error } = await getCommunityAdmins(id);
    if (error) {
      console.warn('Error fetching community admins ', error);
      setCommunityAdminCount(DEFAULT_COMMUNITY_ADMIN_MAX_LIMIT);
      setCommunityAdminMaxLimit(DEFAULT_COMMUNITY_ADMIN_MAX_LIMIT);
      return;
    }
    setCommunityAdminMaxLimit(data?.data?.maxLimit);
    setCommunityAdminCount(data?.data?.currentCount);
  };

  function handleConnectWhatsappBtnClick() {
    if (isDemoCommunity) {
      return handleDemoStateForCta(UNLOCK_WHATSAPP_CTA);
    }

    setShowConnectWhatsAppModals(true);
  }

  function handleCloseWhatsappConnectModals() {
    setShowConnectWhatsAppModals(false);
  }

  function displayWaUnlockedBenefitsCard() {
    setShowWaUnlockedBenefitsCard(true);
  }

  function handleDismissWaUnlockedBenefitsCard() {
    setShowWaUnlockedBenefitsCard(false);
  }

  function localEditActiveCommunity(payload) {
    setActiveCommunity((prev) => ({
      ...prev,
      ...payload
    }));
  }

  async function selectWaBotNumberForCommunity() {
    try {
      const communityId = activeCommunity?._id;
      const { data, error } =
        await setWaBotNumberForCommunity(communityId);

      const botWhatsappId = data?.bot?.botWhatsappId;

      if (error || !botWhatsappId) return;

      const formattedBotNumber =
        getPhoneNumberFromWaPhoneId(botWhatsappId);
      localEditActiveCommunity({
        managedByWhatsappBot: formattedBotNumber
      });

      return true;
    } catch (e) {
      console.warn('Error in selectWaBotNumberForCommunity ', e.message);
      return false;
    }
  }

  const decrementMembershipPendingApplicationCount = () =>
    setMembershipPendingApplicationCount((prev) => prev - 1);

  const getTaskProgress = async () => {
    const { data, error } =
      await getTaskProgressService(activeCommunityId);

    if (error) {
      showErrorToast(error);
      return;
    }

    if (data?.percentageOfTasksCompleted) {
      setTaskProgressPerc(data.percentageOfTasksCompleted);
    }
  };

  const refreshTaskProgressPerc = () => getTaskProgress();

  useEffect(() => {
    // if showConnectWhatsAppModals is true and active community does not have managedByWhatsappBot key then call an api
    if (
      showConnectWhatsAppModals &&
      !activeCommunity?.managedByWhatsappBot
    ) {
      selectWaBotNumberForCommunity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCommunity?.managedByWhatsappBot, showConnectWhatsAppModals]);

  useEffect(() => {
    if (!localStorageService.getItem('makeMoneyModalOpened')) {
      const timer = setTimeout(() => {
        setOpenMakeMoneyModal(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      user &&
      (pathname.startsWith(CM_PORTAL_HOMEPAGE_ROUTE) ||
        pathname.startsWith(MEMBER_PORTAL_BASED_PATH) ||
        pathname.startsWith(USER_BASED_PATH) ||
        pathname.startsWith(PAYMENT_METHOD))
    ) {
      getAllCommunities();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  // Only refresh task progress when on portal
  useEffect(() => {
    if (
      activeCommunity?._id &&
      user &&
      pathname.startsWith(CM_PORTAL_HOMEPAGE_ROUTE)
    ) {
      getCommunityAdminAsync(activeCommunity._id);
      refreshTaskProgressPerc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCommunity?._id, user]);

  useEffect(() => {
    // Reset showConnectWhatsAppModals State if community is changed
    setShowConnectWhatsAppModals(false);

    //when the active community is changed, we need to update the cookie value
    if (activeCommunity?._id) {
      CookieService.set(COMMUNITY_ID_KEY, activeCommunity?._id);
    }

    //save activeCommunity in session storage
    sessionStorageService.setItem(
      'activeCommunity', // useful for identifying active community while routes tracking
      activeCommunity ? JSON.stringify(activeCommunity) : ''
    );

    // if router query contains activeCommunityId, change it to new activeCommunityId
    if (currentRouterQuery.activeCommunityId && activeCommunityId) {
      router.replace({
        query: {
          ...currentRouterQuery,
          activeCommunityId: activeCommunityId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCommunity?._id]);
  useEffect(() => {
    if (activeCommunity?.request_approval)
      setMembershipPendingApplicationCount(
        activeCommunity?.pendingApplications
      );
    else setMembershipPendingApplicationCount(0);
  }, [
    activeCommunity?.pendingApplications,
    activeCommunity?.request_approval
  ]);

  // Refresh community data once after 5s.
  // BE needs this to ensure community has latest config data. Introduced for aug2024FiveDollarCompaign
  useEffect(() => {
    if (!activeCommunityId || !currentRouterQuery?.withAsyncMutate) return;
    const timeoutId = setTimeout(() => {
      getAllCommunitiesAsync(activeCommunityId);
    }, 5000);

    return () => clearTimeout(timeoutId);
    // getAllCommunitiesAsync need not be part of deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCommunityId, currentRouterQuery?.withAsyncMutate]);

  const value = {
    allCommunities,
    activeCommunity,
    localEditActiveCommunity,
    isWhatsappExperienceCommunity,
    loadingCommunities,
    loadingCommunitiesAsync,
    communitiesErr,
    setActiveCommunity: (updatedActiveCommunity) => {
      // @TRENT check where are we using this and why
      // set updated active community id in cookie
      CookieService.set(COMMUNITY_ID_KEY, updatedActiveCommunity._id);

      const isManager = updatedActiveCommunity?.isManager;

      // if user is manager in updatedCommunity, just go to CM portal automatically
      if (isManager) {
        goToCmpHomeWithNewActiveCommunityId(updatedActiveCommunity._id);
        return;
      }

      // if user is only a manager in updatedCommunity, just go to member portal automatically
      router.push(updatedActiveCommunity?.link);
    },
    getAllCommunities,
    getAllCommunitiesAsync,
    getCommunityAdminAsync,
    updateActiveCommunity,
    communityAdminCount,
    communityAdminMaxLimit,

    openMakeMoneyModal,
    setOpenMakeMoneyModal,

    showConnectWhatsAppModals,
    handleConnectWhatsappBtnClick,
    handleCloseWhatsappConnectModals,
    showWaBotConnectionBenefits,
    displayWaUnlockedBenefitsCard,
    handleDismissWaUnlockedBenefitsCard,

    membershipPendingApplicationCount,
    decrementMembershipPendingApplicationCount,
    setMembershipPendingApplicationCount,

    taskProgressPerc,
    setTaskProgressPerc,
    refreshTaskProgressPerc,

    isWelcomeMessageEditorOpen,
    setIsWelcomeMessageEditorOpen,

    // Nasio Processing Fee Config
    nasioFeePreferenceIsSet,
    verifyNasioFeePreferenceIsSet,
    showFeePreferencePopup,
    closeFeePreferencePopup,
    updateNasioFeePreference,
    isSettingFeePreference,
    takeRateFeePercentage
  };

  return (
    <CommunityPortalContext.Provider value={value}>
      <IntercomContextProvider>{children}</IntercomContextProvider>
    </CommunityPortalContext.Provider>
  );
};

export const useCommunityPortalContext = () =>
  useContext(CommunityPortalContext);
